import React from 'react';
import G1 from '../img/creativity_4359757.png';
import G2 from '../img/branding_11zon.webp';
import G3 from '../img/custom-illutration.svg';
import G4 from '../img/graphic-designing-process.svg';


const GraphicPage = () => {
    return (
        <>
            <section className="graphic-banner" style={{ backgroundImage: 'url(/path-to-your-graphic-banner.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff' }}>
                <div className='d-flex flex-column align-items-center justify-content-center text-center py-5'>
                    <h1 style={{ fontSize: '70px', fontWeight: 'bold' }}>Graphic Design</h1>
                    <p style={{ fontSize: '20px', maxWidth: '600px' }}>
                        We create stunning visuals that captivate and communicate your brand's story effectively.
                    </p>
                </div>
            </section>

            <section className="graphic-services py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <h2 className="text-center mb-4">Our Graphic Design Services</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="graphic-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={G1} alt="Logo Design" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">Logo Design</h3>
                                <p>Crafting unique and memorable logos that represent your brand identity.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="graphic-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={G2} alt="Branding" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">Branding</h3>
                                <p>Building a strong brand presence through consistent and impactful design.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="graphic-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={G3} alt="Illustrations" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">Custom Illustrations</h3>
                                <p>Creating bespoke illustrations that enhance your brand's visual appeal.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="graphic-process py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Our Design Process</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={G4} alt="Design Process" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <ul className="process-list">
                                <li><strong>1. Discovery:</strong> Understanding your brand, goals, and design preferences.</li>
                                <li><strong>2. Conceptualization:</strong> Developing initial concepts and ideas.</li>
                                <li><strong>3. Design:</strong> Creating the final design with attention to detail.</li>
                                <li><strong>4. Feedback:</strong> Refining the design based on your input.</li>
                                <li><strong>5. Delivery:</strong> Providing the final files in all necessary formats.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="graphic-portfolio py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <h2 className="text-center mb-4">Our Portfolio</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <img src="/path-to-portfolio-image1.jpg" alt="Portfolio 1" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-4">
                            <img src="/path-to-portfolio-image2.jpg" alt="Portfolio 2" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-4">
                            <img src="/path-to-portfolio-image3.jpg" alt="Portfolio 3" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="graphic-cta py-5 text-center" style={{ backgroundColor: '#007bff', color: '#fff' }}>
                <div className="container">
                    <h2>Ready to Elevate Your Brand?</h2>
                    <p>Contact us today to start a project that will set your brand apart with stunning visuals.</p>
                    <a href="/contact" className="btn btn-light btn-lg mt-3">Get in Touch</a>
                </div>
            </section>
        </>
    );
}

export default GraphicPage;

import React from 'react'
const TandC=()=>{
    return(
        <section>
            <div className="container">
                <div className="row">
                <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
                color: "rgb(6, 104, 251)",
              }}
            >
              Terms & Conditions
            </h2>
            <hr />
          </div>
          <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >Last updated: 23 Aug, 2024</h5>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >   1. DEFINITION OF TERMS</h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >ForgeWebs – ForgeWebs (Hereafter as ForgeWebs) having its principal place of business at Siwan , Bihar,India,841226
            The Client – the entity which enters into a contract with ForgeWebs.
            Domain Name – the root address of a website, e.g. www.forgewebs.com All such names must be registered with the appropriate naming authority, which will usually charge a fee.
            Downtime – time when the website is not accessible via the Internet. This may be because of a technical failure of the Host or because work is being carried out on the site.
            Host – the company on whose system the Website Physically resides.
            Link, Hyperlink – a ‘clickable’ link embedded on a web page which may take the form of a graphic or text.
            Search Engine – a website which contains a directory of websites on the Internet enabling users to find websites by subject matter classification.
            Website – a collection of web pages and associated code which forms an integrated presence
            The Work – the subject matter of the contract between the Client and ForgeWebs.
            </p>
             <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >  2. FEES </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >2.1 Fee Payable
            A non refundable deposit of 40% of the total fee payable under the contract is due immediately upon the signing of the contract. The remaining 60% shall become due when the Work is completed to the reasonable satisfaction of the Client but subject to the terms of Clause 4.3 Approval of Work and Clause 4.4 Rejected Work here of. Forge Webs reserves the right not to begin the Work until the said deposit has been paid in full. The fee quoted in the contract does not include the cost of domain registration, hosting set up fee or hosting.
            2.2 Maintenance Fees
            Maintenance, if included in the contract, shall be on a month-to-month basis, with a minimum of $50 payable in any month where updating is necessary. Fees will be assessed on an hourly basis at $50 per hour or part thereof. No fee will be required in a month where no updating is necessary. Search engine re-submissions, other than the original submission included in the contract fee, shall be included in the maintenance fee.
            </p>    
             <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >3. DISCLAIMERS   </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >3.1 Third Parties
            Forgewebs can take no responsibility for services provided by third parties through us or otherwise, including the Hosting of the Client’s Website, although ForgeWebs will endeavour to ensure that Website downtime is kept to a minimum.
            3.2 Maintenance and Correction of Errors
            ForgeWebs takes no responsibility for the functionality or maintenance (unless a maintenance contract is in place) of the Website after the Work has been completed. Errors (both technical and typographical) attributable to ForgeWebs will be corrected free of charge, but ForgeWebs reserves the right to charge a reasonable fee for correction of errors for which ForgeWebs is not responsible, including, but not limited to malicious modification of the Website by a third party and typographical errors contained in materials provided to ForgeWebs by the Client.
            3.3 Extent of Work
            Installation on the Internet is limited to the uploading of all necessary files to the Host, and testing of functionality. No registration of the Website with search Engines will be undertaken unless otherwise agreed with the Client.
            3.4 Consequential Loss
            Under no circumstances will ForgeWebs be responsible or liable for financial or other loss or damage caused by the failure or use or misuse of its software. The Client should ensure that data on their site is regularly backed up and that a contingency plan is in place to minimize possible losses as a result of software failure.
            3.5 Status and Duration of Offers
Proposals and offers are valid for a maximum period of one month from the date issued. ForgeWebs is not bound to honour offers that have expired. Offers are not legally binding until an acceptable timetable for the work has been agreed by both parties. This timetable must be agreed within the month that the offer is valid. If an acceptable timetable has not been approved by both parties within one month of the offer being made, the offer is deemed to have expired.
Below part (3.6) is optional
3.6 Search Engine Listings
ForgeWebs does not guarantee listings on Search Engines and the Client accepts that it is Search Engines and not ForgeWebs who determine whom they list and whom they will not. The Client further understands there is no guaranteed placement or rank on the Search Engines and that a new website may never even appear on Search Engines at all. ForgeWebs does not control Search Engines’ algorithms and huge shifts can appear daily, weekly and even hourly.
</p>
             <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >4. COMPLETION OF WORK AND PAYMENT   </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >4.1 Completion of Work
            Forgewebs warrants completing the work in accordance with its standard Terms and Conditions to the specifications previously agreed with the Client. Client acknowledges that ForgeWebs is not bound by any set deadline due to the fact there are many aspects of development which require information from the client and other 3rd parties, response times from client and/or 3rd parties can delay a project beyond the control of Forgewebs. ForgeWebs will not charge more than the amount previously agreed unless the Client has varied the specifications of the Work since the agreement. ForgeWebs will not undertake changes to the specifications of the Work which would increase the cost, without prior written authorization from the Client or else quotation will be changed and modified price will valid.
            4.2 Supply of Materials
The Client is to supply all materials and information required for ForgeWebs to complete the work in accordance with the agreed specification. Such materials may include, but are not limited to, photographs, written-copy, logos and other printed materials. Where the Client’s failure to supply such materials leads to a delay in completion of the work, ForgeWebs has the right to extend previously agreed deadlines for the completion of the work by a reasonable amount. Where the client’s failure to supply materials prevents progress on the work for more than 21 days, ForgeWebs has right to invoice the Client for any part or parts of the work already completed.
4.3 Approval of Work
On completion of the work, the Client will be notified and have the opportunity to review it The Client should notify ForgeWebs, in writing of any unsatisfactory points within 7-days of receipt of such notification. Any of the work which has not been reported in writing to ForgeWebs as unsatisfactory within the 7-day review period will be deemed to have been approved. Once approved, or deemed approved, work cannot subsequently be rejected, and the contract will be deemed to have been completed and the 50% balancing payment under Clause 2.1 Fee Payable will become due. The Contract will remain in effect until all obligations have been completed in terms of this Clause.
4.4 Rejected Work
If the Client rejects the work within the 7-day review period, or will not approve subsequent work performed by ForgeWebs to remedy any points reported by the Client as unsatisfactory, and G Web Pro considers that the Client is unreasonable in his repeated rejection of the work, the contract will be deemed to have expired and ForgeWebs can take any legal measures to recover both payment for the completed work and reasonable expenses incurred in recovering payment.
4.5 Payment
Upon completion of 7-day review period, ForgeWebs will charge the Client for the 50% balancing payment in accordance with Clause 2.1 Fee Payable hereof, which, in the absence of agreement to the contrary, is to be paid by the Client within 21 days of the date that the invoice was issued.
4.6 Remedies for Overdue Payment
If Payment has not been received by the due date, ForgeWebs has the right to suspend ongoing work for Client, until such time that full payment of the outstanding balance has been received. If full payment has still not been received 21 days after the due date, ForgeWebs has the right to replace, modify or remove the Website and revoke the Client’s licence of the work until full payment has been received. By revoking the Client’s licence of the work or removing the web site from the Internet, ForgeWebs does not remove the Client’s obligation to pay any outstanding monies owing. ForgeWebs reserves the right to a legal action against the Customer for any outstanding balance. Customer is responsible for paying all taxes, government charges, reasonable expenses and legal fees ForgeWebs incurs collecting outstanding amounts. For outstanding amounts, ForgeWebs will charge a $50 monthly administration fee in addition to the Indian Reserve bank rate. To the fullest extent permitted by law, Customer waives all claims relating to charges unless claimed within 30 days after the initial charges.
</p>
             <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            > 5. INTELECTUAL PROPERTY  </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >5.1 Offers and Proposals
            Offers and proposals made by ForgeWebs to potential clients should be treated as trade secrets and remain the property of ForgeWebs. Such offers and proposals or the information contained within them must not be passed to third parties or publicly disseminated without prior written authorization from ForgeWebs. This includes, but is not limited to, technical features, functionality, aspects of the design and pricing information.
            5.2 Warranty by Client as to Ownership of Intellectual Property Rights
            The Client will obtain all the necessary permissions and authorities in respect of the use of all copy, graphic images, registered company logos, names and trademarks or any other material it supplies to ForgeWebs for inclusion on the Website. The conclusion of a contract between ForgeWebs and the Client shall be regarded as a guarantee by the Clients to ForgeWebs that all such permissions and authorities have been obtained and that the inclusion of such material on the Website would not constitute a criminal offence or civil infraction. By agreeing to these terms and conditions, the Client removes the legal responsibility of ForgeWebs and indemnifies the same from any claims or legal actions however related to the content of the Client’s site.
            5.3 Domain Name
Any Domain Name obtained will belong to the Client. The Client agrees to indemnify ForgeWebs, including any incidental costs, against any claims that a Domain Name applied for, or obtained, violates the intellectual property rights of a third party. The Client warrants that the domain name sought is not a trademark of a third party. The developer will secure a domain name for the Client at the Client’s request. All charges incurred in doing so will be billed to the Client as an addition to the base price contemplated by this agreement. This is not a source of income for the Developer. If the Client already has a domain name, the Developer will coordinate redirecting the address to the new host.
5.4 Licensing
Once ForgeWebs has received full payment of all outstanding invoices and the Work has been approved by the Client in accordance with Clause 4.3 hereof, the Client will be granted a licence to use the Website and its contents.
5.5 Images and Content
Any images or content used on the website is the sole responsibility of the client. The client ensures there is no copyright law broken when using directly or accepting to use any text or image content on the website, banner ads, social media accounts, press releases, blogs, etc. By accepting any content to be displayed on the site, this includes agreeing to launch the project, the client accepts all responsibility related to the content and images used on the website.
</p>
             <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            > 6. RIGHTS AND RESPONSIBILITIES  </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >6.1 Right to Terminate
            ForgeWebs reserves the right to refuse or break a contract without prior notice, if it is believed that the Client, their Website, or any material is illegal, immoral or otherwise unacceptable.
            6.3 Web Site Design Credit
            All ForgeWebs web design clients will have a website design by ForgeWebs link on their website in the footer of every page. This is non-negotiable. By hiring ForgeWebs to work on your website you are agreeing to this, and that you understand that this link may NOT be removed without consent You may pay ForgeWebs a fee of $1050 to have this link permanently removed. Should you have a new layout designed for your site by a company other than Forgewebs, you may remove the credit from your website.
            6.5 Copyright & Ownership
            Upon completing of the project, ForgeWebs transfers all rights and ownership of custom designs and programming written by ForgeWebs to the client. Software and third party graphics or programs are not transferred to the client and remain under copyright of their respective owners All Pre-Designed websites may be purchased from a third party and thus all rights, ownership of design and programming are not transferred to the clients and client cannot claim intellectual property rights or exclusive ownership Pre-designed websites may also be used as templates to create websites for other clients. Original design files may be given to the client depending on the circumstances If design is being transferred to another service provider a rate of $100 per webpage will be charged. if requested within the first 12-months of site development.
            ForgeWebs reserves the right to resell custom designed web sites that remain unpaid by the original client, unaccepted mocks or other graphics created by ForgeWebs but not in legal use by the client. ForgeWebs reserves the right to display websites and graphics that have been designed by ForgeWebs on their website, and in any marketing material to aid as examples of our work.
Website designs and mock-ups submitted for the purpose of Request for Proposal are property of ForgeWebs until the quote is paid Duplication, simulation or alteration of the design by the client or another service provider is prohibited. If in the instance the mock-up designs are transferred to another service provider, ForgeWebs reserves the right to bill the client the original quoted price with an additional $1000 charge for misappropriation of copyrighted materials.
</p>
             <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            > 7 INTERPRETATION  </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >7.1 Jurisdiction
            This Agreement shall be governed by the laws of India which shall claim venue and jurisdiction for any legal action or claim arising from the contract between ForgeWebs and the Client. The said contract is void where prohibited by law.
            7.2 Survival of Contract
            Where one or more terms of the said contract are held to be void or unenforceable for whatever reason, any other terms of the contract not so held will remain valid and enforceable at law.
            7.3 Acceptance
            Please not that by submitting your down payment for our services you are agreeing to all of our terms of service as written here. ForgeWebs reserves the right to change or update these terms at any time without prior notice. Any change done will be notified on our website only.
            </p>
             <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            > 8. MISCELLANEOUS  </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >8.1 Hosting Fees and Terms
            All the Domain and Hosting cost will be availed by the client.
            We don’t provide free hosting and Domain services as of now.
            8.2 Website Traffic Reporting
            Website Traffic Reporting subscribers will receive monthly activity reports through email. Reports provide detailed information about the number of visitors to Site Owner’s website, the individual pages visited and referrals to the Site Owner’s website from other websites and Search Engines.
            8.3 Mobile Application and Web application Development
            All the above terms and conditions for website development also apply for mobile Application and Web Application development.
            </p>             

                </div>
            </div>
        </section>
    )
}
export default TandC;
import {Accordion} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './WebDevPage.css';
const WebDevPage=()=>{
    return(
        <>
        <section id='webdev-banner'>
        <div className='px-3 d-flex text-light flex-column align-items-center justify-content-center text-center py-5'>
            <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>Website Development</h1>
            <p style={{ fontSize: '20px', maxWidth: '600px' }}> We build powerful, responsive websites that enhance your brand and engage your audience.</p>
            </div>
        </section>
        <section className='py-5'>
            <div className="container">
                <div className="row">
                    <div className='col lg-6 d-flex justify-content-center flex-column'><h1  style={{ fontSize: '2rem' }}>Why Choose<span style={{ color: '#00b0ff', fontWeight: '700' }}>Forgewebs</span>?</h1>
                    <p>We Don't Just Build Wevsites; We Create Experiences That Make Your Compititor Weep.Our Team Is a Blend of Tech Wizards And Creative Geniuses.</p></div>
                    <div className="col lg-5">
                    <Accordion className="accordion accordion-steps">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h1>Custom Design</h1>
                                </Accordion.Header>
                                <Accordion.Body>
                                    We don't do Cookie-cutter.Your Website will be as Unique as Your Grandma's Secret Recipe!
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <h1>Lightning Fast Speed</h1>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Need it yesterday ? We'll make it happen faster than you can say 'deadline'!
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <h1>Unmatched Support</h1>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Our support team is like your favorite pizza place always there when you need us! 
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>

        </section>
        <section>
    <div className="container text-center py-5">
        <div className="row gy-4">
            <div className="col-md-4">
                <div className="p-3 border rounded shadow-sm bg-primary text-light">
                    <h3>Landing Pages</h3>
                </div>
            </div>
            <div className="col-md-4">
                <div className="p-3 border rounded shadow-sm bg-success text-light">
                    <h3>Custom Websites</h3>
                </div>
            </div>
            <div className="col-md-4">
                <div className="p-3 border rounded shadow-sm bg-warning text-light">
                    <h3>E-commerce Websites</h3>
                    <p></p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="p-3 border rounded shadow-sm bg-danger text-light">
                    <h3>Educational Websites</h3>
                </div>
            </div>
            <div className="col-md-4">
                <div className="p-3 border rounded shadow-sm bg-info text-light">
                    <h3>Hotels & Restaurants</h3>
                </div>
            </div>
            <div className="col-md-4">
                <div className="p-3 border rounded shadow-sm bg-light">
                    <h3>Website Re-design</h3>
                </div>
            </div>
            <div className="col-md-4 mx-auto">
                <div className="p-3 border rounded shadow-sm bg-dark text-light">
                    <h3>WordPress to Code-based Websites</h3>
                </div>
            </div>
        </div>
        <div className="mt-4">
            <p className="lead">
                We offer a wide range of services tailored to your needs, from landing pages to fully custom-built websites. Our team is dedicated to creating the perfect online presence for your business.
            </p>
        </div>
    </div>
</section>
<section className='bg-primary text-light py-5'>
    <div className="container">
        <div className="row"><div><h3>Increase your buisness and its Online Presence! Talk To Our EXperts</h3> <Link  as={Link} to="/contact" className="btn btn-light btn-lg mt-3">Get in Touch</Link></div> </div>
    </div>
</section>

        </>

    )
}
export default WebDevPage;
import React from 'react'
import './Contact.css';
import Contact from '../components/Contact';
const ContactPage=()=>{
    return(
        <div>
        <section className='contact-page'>
           <div className='py-3 px-3'><h1 style={{fontSize: '70px'}}>Connect with Our Experts</h1>
           </div>             
        </section>
        <section className='py-5'>
            <div className='d-flex justify-content-center '>
            <h1>
            Make Your Business Growth Sky High <br />
            <span style={{color: '#0668FB'}}> Get in Touch with Forgewebs!</span>
            </h1></div>
        </section>
        <Contact/>
        </div>

    )
}
export default ContactPage;
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CollapsibleExample  from './components/Navbar';
import ScrollToTop from "./components/ScrollToTop";
import {About} from './components/About';
import { OurTeam } from './components/OurTeam';
import {Footer} from './components/Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Homepage from "./components/Home";
import WebDevPage from "./components/WebDevPage";
import Privacy from "./components/Privacy";
import FAQ from "./components/Faqs";
import TandC from "./components/Terms";
import ContactPage from "./components/Contactpage";
import AppDevPage from "./components/AppDevPage";
import GraphicPage from "./components/GraphicPage";
import DigitalMarketingPage from './components/DigtalMarketingPage';



function App() {
  return (
    <Router>
      < ScrollToTop/>
    <div className="App">
      <CollapsibleExample/> 
      <Routes>
      <Route path="/" element={<Homepage/>}/>
      <Route path="/about" element={  <About/>}/>      
      <Route path="/website-development" element={<WebDevPage/>}/>
      <Route path="/app-development" element={<AppDevPage/>}/>
      <Route path="/graphic-design" element={<GraphicPage/>}/>
      <Route path="/digital-marketing" element={<DigitalMarketingPage/>}/>
      <Route path="/team" element={<OurTeam/>}/>
      <Route path="/contact" element={<ContactPage/>}/>    
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/faq" element={<FAQ/>}/>
      <Route path="/term-&-condition" element={<TandC/>}/>
      </Routes>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;

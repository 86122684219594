import { Container, Row, Col, Card } from "react-bootstrap";
import gymImg from '../img/200_11zon.webp';
import educationImg from '../img/educationalimg.webp';
import hotelImg from '../img/201_11zon.webp';
import tourImg from '../img/2149153263_11zon.webp';
import StartupImg from '../img/124296_11zon.webp';
import "./Industry.css";

const cardData = [
  {
    src: educationImg,
    alt: "Education",
    title: "Education",
    text: "We have extensive experience in the educational sector, delivering projects for educational technology companies, schools, colleges, and universities.",
  },
  {
    src: gymImg,
    alt: "Gym & Sports",
    title: "Gym & Sports",
    text: "Our expertise in the gym and sports industry includes developing solutions for fitness centers, sports clubs, and wellness apps to enhance user engagement and management.",
  },
  {
    src: hotelImg,
    alt: "Hotels",
    title: "Hotels & Restaurants",
    text: "We specialize in creating tailored solutions for hotels and restaurants, including reservation systems, customer management, and digital marketing strategies.",
  },
  {
    src: tourImg,
    alt: "Tour & Travel",
    title: "Tour & Travel",
    text: "We offer innovative solutions for the tour and travel industry, such as booking systems, travel management platforms, and customer engagement tools.",
  },
  {
    src: StartupImg,
    alt: "Startups",
    title: "Startups",
    text: "We support startups by providing scalable solutions, from initial concept development to market-ready products, helping them achieve their business goals efficiently.",
  }
];

const IndusrtySection = () => {
  return (
    <section className="course" id="course">
      <Container style={{ background: 'aliceblue' }}>
        <Row className="justify-content-center">
          <div className="course-title">
            <h2
              style={{
                fontWeight: "400",
                fontFamily: "Margarine, sans-serif",
                fontStyle: "normal",
              }}
              className="animate__animated animate__fadeInLeft"
            >
              Our Working Domains and Expertise in Industries
            </h2>
            <hr />
          </div>
          {cardData.map((card, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className="d-flex justify-content-center align-items-center"
              style={{ marginBottom: "3rem" }}
            >
              <Card className="card-container">
                <Card.Img
                  variant="top"
                  src={card.src}
                  alt={card.alt}
                  className="card-img"
                  width="160"
                  height="160"
                />
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <div className="card-overlay">
                    <Card.Text className="card-text">{card.text}</Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default IndusrtySection;

import React from 'react'
import { Link } from 'react-router-dom';
import './Home.css';
import {OurTeam} from '../components/OurTeam';    
import {Service} from '../components/ServiceCards';
import FAQ from './Faqs';
import IndusrtySection from './Industry';
import Contact from '../components/Contact';
const Homepage =()=>{
return(
    <>
    {/* <CarouselFadeExample/> */}
    <section id='home'>
    <div className="home-container">
      <div className="parallax"></div>
      <div className="content">
        <h1>Innovative Web and App Solutions</h1>
        <p style={{fontFamily: 'Margarine, calibri',fontSize: '1.5rem',margin: '20px 0'}}> At Forgewebs, we craft cutting-edge websites and mobile applications tailored to your unique business needs. Experience innovation and functionality that drive results.</p>
        <button className="cta-button" onClick={()=> document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>Connect Now </button>
      </div>
    </div>
    </section>
    <section className='pt-5'>
        <div className="container">
            <div className="row"><h1>Welcome to the Digital World with Forgewebs</h1>
            <p>Elevate your bussiness Today !</p></div>
        </div>
    </section>
    <section className='py-5 mt-5 mb-5' style={{background: '#0668fb'}}>
        <div className="container">
            <div className='row'>
                <div className='col'><div style={{color: '#fff'}}><h2>60+</h2><p>website Development</p></div></div>
                <div className='col'><div style={{color: '#fff'}}><h2>25+</h2><p>App Development</p></div></div>
                <div className='col'><div style={{color: '#fff'}}><h2>100+</h2><p>Small Business Are Growing</p></div></div>
                <div className='col'><div style={{color: '#fff'}}><h2>100%</h2><p>Service Satisfaction</p></div></div>
            </div>
        </div>
       </section>
       <section>
        <div className="container">
            <div><h1>What we <span style={{color: 'rgb(6, 104, 251)',fontWeight: '700'}}> Offers</span> </h1></div>
            <div className="row">
            <Service/>
            </div>
        </div>
       </section>
       <section className='py-5 mt-2' style={{background: 'rgb(6, 104, 251)'}} >
        <div className="container">
            <div className="row py-3">
                <div className="col text-light"><h2>Talk to Our Highly Talented Experts</h2></div>
                <div className="col"><Link as={Link} to="/contact"><button style={{border: 'none', padding: '0.8rem' ,background: '#fff'}}> <h3> Talk to Expert</h3></button></Link></div>
            </div>
        </div>
       </section>
       <IndusrtySection/>
       <OurTeam/>
       <Contact/>
       <FAQ />
    </>
)
}
export default Homepage;

import React from 'react';
import D1 from '../img/seo_4522557.png';
import D2 from '../img/ppc.png';
import D3 from '../img/social-media_4545111.png';
import D4 from '../img/D4.svg';


const DigitalMarketingPage = () => {
    return (
        <>
            <section className="dm-banner" style={{ backgroundImage: 'url(/path-to-your-digital-marketing-banner.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff' }}>
                <div className='d-flex flex-column align-items-center justify-content-center text-center py-5'>
                    <h1 style={{ fontSize: '70px', fontWeight: 'bold' }}>Digital Marketing</h1>
                    <p style={{ fontSize: '20px', maxWidth: '600px' }}>
                        Elevate your brand's online presence with our data-driven digital marketing strategies.
                    </p>
                </div>
            </section>

            <section className="dm-services py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <h2 className="text-center mb-4">Our Digital Marketing Services</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="digital-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={D1} alt="SEO" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">SEO</h3>
                                <p>Optimize your website to rank higher on search engines and drive organic traffic.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="digital-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={D2} alt="PPC" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">PPC Advertising</h3>
                                <p>Maximize your ROI with targeted pay-per-click campaigns that convert.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="digital-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={D3} alt="Social Media Marketing" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">Social Media Marketing</h3>
                                <p>Engage your audience and build a strong social media presence across platforms.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dm-approach py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Our Approach</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={D4} alt="Our Approach" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <ul className="approach-list">
                                <li><strong>1. Research:</strong> We analyze your industry, competitors, and target audience to create a tailored strategy.</li>
                                <li><strong>2. Strategy:</strong> We develop a comprehensive digital marketing plan that aligns with your business goals.</li>
                                <li><strong>3. Execution:</strong> Our experts implement the strategy with precision, focusing on achieving the best results.</li>
                                <li><strong>4. Analysis:</strong> We monitor performance and adjust the strategy as needed to ensure continued success.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="dm-case-studies py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <h2 className="text-center mb-4">Case Studies</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="case-study-card p-4" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src="/path-to-case-study1.jpg" alt="Case Study 1" className="img-fluid rounded" />
                                <h4 className="mt-3">Boosting Organic Traffic by 200%</h4>
                                <p>Learn how we helped a client double their organic traffic through effective SEO strategies.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="case-study-card p-4" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src="/path-to-case-study2.jpg" alt="Case Study 2" className="img-fluid rounded" />
                                <h4 className="mt-3">Increasing Conversion Rates with PPC</h4>
                                <p>Discover how our PPC campaign optimization led to a 50% increase in conversions.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="case-study-card p-4" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src="/path-to-case-study3.jpg" alt="Case Study 3" className="img-fluid rounded" />
                                <h4 className="mt-3">Social Media Campaign Success</h4>
                                <p>Explore how our social media strategy helped a brand grow its followers and engagement exponentially.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="dm-cta py-5 text-center" style={{ backgroundColor: '#007bff', color: '#fff' }}>
                <div className="container">
                    <h2>Ready to Transform Your Digital Presence?</h2>
                    <p>Let's work together to create a digital marketing strategy that drives results.</p>
                    <a href="/contact" className="btn btn-light btn-lg mt-3">Contact Us</a>
                </div>
            </section>
        </>
    );
}

export default DigitalMarketingPage;

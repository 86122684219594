import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import member1 from '../img/ishant_11zon.webp';
import member2 from '../img/shivendu.jpeg';
import member3 from '../img/akash_11zon.webp';
import member4 from '../img/akansha_11zon.webp';
import './OurTeam.css';
import TrackVisibility from 'react-on-screen';

export const OurTeam = () => {
  const teamMembers = [
    { img: member1, name: 'Ishant Bachchas', title: 'Founder & Developer', description: 'Ishant leads the team with a vision for the future.' },
    { img: member2, name: 'Shivendu', title: 'Founder & Project Manager', description: 'Shivendu is responsible for Dealing Project Mangement' },
    { img: member3, name: 'Akash Chaudhary', title: 'Lead Developer', description: 'Akash Leads The Team of Developer to Deliver the Product' },
    { img: member4, name: 'Akansha Chaudhary', title: 'Graphic Designer & Developer', description: 'Akansha is Responsible For Making Visual Appealing Graphics For The Forgewebs' },
  ];

  return (
    <section className="our-team" id="our-team">
      <Container>
        <div className="section-title" >
        <TrackVisibility>
        {({ isVisible }) =>(<h2 style={{fontSize: '50px'}} className={isVisible ? "animate__animated animate__shakeX" : ""}>Our <span style={{color: 'rgb(6, 104, 251)',fontWeight: '700'}}> Team</span></h2>)}</TrackVisibility></div><hr />
        <Row>
          {teamMembers.map((member, index) => (
            <Col md={3} sm={6} xs={12} key={index} className="team-member">
              <div className="member-image" style={{ backgroundImage: `url(${member.img})` }}></div>
              <div className="member-info">
                <h3>{member.name}</h3>
                <p className="title">{member.title}</p>
                <p>{member.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

import { Container, Row, Col, Card } from 'react-bootstrap';
import service1 from '../img/webdevelopment.svg';
import service2 from '../img/app development.svg';
import service3 from '../img/digital marketing.svg';
import service4 from '../img/graphic design.svg';
import './Service.css';

const ServiceCards = () => {
  return (
    <section className="service" id="service">
      <Container>        
        <hr />
        <Row>
          <Col md={3} sm={6} xs={12}>
            <Card className="service-card">
              <Card.Img variant="top" src={service1} />
              <Card.Body>
                <Card.Title>Web Development</Card.Title>
                <Card.Text className="card-text">
                  Transform your online presence with Forgewebs' cutting-edge website development services. We create responsive, user-friendly, and visually stunning websites tailored to your business needs. Whether you need an e-commerce platform, a corporate site, or a personal blog, our expert developers ensure your site is fast, secure, and optimized for search engines.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <Card className="service-card">
              <Card.Img variant="top" src={service2} />
              <Card.Body>
                <Card.Title>App Development</Card.Title>
                <Card.Text className="card-text">
                  Stay ahead of the curve with Forgewebs' mobile app development services. We design and build custom mobile applications for iOS and Android platforms, ensuring seamless performance and an exceptional user experience. From concept to launch, we provide full-cycle app development to bring your ideas to life and engage your audience effectively.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <Card className="service-card">
              <Card.Img variant="top" src={service3} />
              <Card.Body>
                <Card.Title>Digital Marketing</Card.Title>
                <Card.Text className="card-text">
                  Boost your online visibility and drive more traffic with Forgewebs' comprehensive digital marketing services. Our strategies include SEO, social media marketing, pay-per-click advertising, and email campaigns. We analyze market trends and user behavior to craft personalized campaigns that increase conversions and maximize ROI, helping your business grow and thrive in the digital landscape.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <Card className="service-card">
              <Card.Img variant="top" src={service4} />
              <Card.Body>
                <Card.Title>Graphic Design</Card.Title>
                <Card.Text className="card-text">
                  Make a lasting impression with Forgewebs' top-notch graphic design services. Our talented designers create visually appealing and brand-consistent graphics that capture your audience's attention. From logos and business cards to marketing materials and social media visuals, we deliver high-quality designs that elevate your brand's identity and communicate your message effectively.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export const Service = ServiceCards;
export default ServiceCards;

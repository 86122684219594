import React from 'react';
import iosImg from '../img/ios-dev-icon.png';
import androidImg from '../img/mobile-app-development_16990134.png';
import crossImg from '../img/app-development_2335235.png';
import appDevprocess from '../img/app-dev-process.svg';

const AppDevPage = () => {
    return (
        <>
            <section className="appdev-banner" style={{ backgroundImage: 'url(/path-to-your-banner-image.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff' }}>
                <div className='d-flex flex-column align-items-center justify-content-center text-center py-5'>
                    <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>App Development</h1>
                    <p style={{ fontSize: '20px', maxWidth: '600px' }}>
                        We craft top-notch mobile applications that drive innovation and transform businesses. Our expertise spans across multiple platforms and industries, ensuring your app stands out.
                    </p>
                </div>
            </section>

            <section className="appdev-services py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <h2 className="text-center mb-4">Our Services</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="appdev-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={iosImg} alt="Service 1" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">iOS Development</h3>
                                <p>We build intuitive and high-performance iOS applications tailored to your business needs.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="appdev-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={androidImg} alt="Service 2" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">Android Development</h3>
                                <p>Our Android applications are known for their robust architecture and seamless user experience.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="appdev-card p-4 text-center" style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={crossImg} alt="Service 3" style={{ width: '80px', height: '80px' }} />
                                <h3 className="mt-3">Cross-Platform</h3>
                                <p>Reach a broader audience with our cross-platform apps, built with cutting-edge technology.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="appdev-process py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Our Development Process</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={appDevprocess} alt="Development Process" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <ul className="process-list">
                                <li><strong>1. Consultation:</strong> We discuss your ideas and requirements in detail.</li>
                                <li><strong>2. Design:</strong> Our designers create a user-friendly interface and experience.</li>
                                <li><strong>3. Development:</strong> Our developers bring your app to life with clean, efficient code.</li>
                                <li><strong>4. Testing:</strong> We ensure your app is bug-free and performs optimally.</li>
                                <li><strong>5. Launch:</strong> Your app goes live, ready to impress users.</li>
                                <li><strong>6. Support:</strong> We offer ongoing support and updates to keep your app running smoothly.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="appdev-cta py-5 text-center" style={{ backgroundColor: '#007bff', color: '#fff' }}>
                <div className="container">
                    <h2>Ready to Start Your Project?</h2>
                    <p>Let's create something amazing together. Contact us today to get started on your app development journey.</p>
                    <a href="/contact" className="btn btn-light btn-lg mt-3">Get in Touch</a>
                </div>
            </section>
        </>
    );
}

export default AppDevPage;

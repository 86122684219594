import { Container, Row, Col } from "react-bootstrap";
const Privacy = () => {
  return (
    <section className="privacy-policy" id="privacy-policy">
      <Container>
        <Row style={{ marginTop: "2rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
                color: "rgb(6, 104, 251)",
              }}
            >
              Privacy Policies
            </h2>
          </div>
          <hr />
          <Col>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Last updated: 23 Aug, 2024
            </h5>

            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            ></p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              This privacy policy sets out how ForgeWebs uses and protects any
              information that you give ForgeWebs when you use this website.
            </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              ForgeWebs (hence by referred to as “ForgeWebs″, or “we”, or “us )
              is committed to ensuring that your privacy is protected. Should we
              ask you to provide certain information by which you can be
              identified when using this website, then you can be assured that
              it will only be used in accordance with this privacy policy.
              ForgeWebs may change this policy from time to time by updating
              this page. You should check this page from time to time to ensure
              that you are happy with any changes. This policy is effective from
              25th August 2024.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Information We Collect
            </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              We may collect the following information when you subscribe to our
              email newsletter or fill in our inquiry form: • First name • Last
              name • Email address • Contact information including email address
              • Demographic information such as postcode, preferences, and
              interests • Other information relevant to customer surveys and/or
              offers
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              What we do with this Information
            </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              We require this information to understand your needs and provide
              you with a better service, in particular for the following
              reasons: • Internal record keeping. • We may use the information
              to improve our products and services. • We may periodically send
              you emails about new products, special offers, or other
              information which we think you may find interesting using the
              email address you have provided. • From time to time, we may also
              use your information to contact you for market research purposes.
              We may contact you by email, phone, fax, or mail. We may use the
              information to customize the website according to your interests.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Security
            </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorized access or disclosure, we have put in
              place suitable physical, electronic, and managerial procedures to
              safeguard and secure the information we collect online.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Cookies
            </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              We use cookies on this website. A cookie is a small file placed on
              your computer’s hard drive with your implied consent. Cookies
              allow web applications to remember you and personalize your
              experience. The website can tailor its operations to your needs,
              likes, and dislikes by gathering and remembering information about
              your preferences.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Google Analytics & Conversion Tracking
            </h5>
            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              We use traffic log cookies from Google Analytics to identify which
              pages are being used and is an essential part of monitoring and
              improving our electronic communications. This helps us data
              analysis about web page traffic and improve our website in order
              to tailor it to customer needs. We use this non-identifiable
              aggregated traffic information for statistical analysis purposes.
              We also use conversion tracking to understand customer behaviour.
              Session Cookies This site uses essential session cookies for the
              operation of the website.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Google Adwords Cookies
            </h5>

            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              We may use cookies in Google Adwords for remarketing purposes.
              This means we remember your visit to our website to provide you
              with advertising elsewhere on the Google Display Network.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Other Applications
            </h5>

            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              If we embed YouTube videos in the site, watching the video will
              set a cookie on your computer. We may also use embedded
              applications such as Twitter, Facebook, Instagram, LinkedIn. These
              third parties may set cookies to remember your preferences.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              How to Disable Cookies
            </h5>

            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. This may prevent
              you from taking full advantage of the website. • Google Chrome •
              Safari • Firefox If you disable cookies, your experience with some
              parts of the website may be affected.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Links to Other Websites
            </h5>

            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              Our website contains links to other websites of interest. However,
              once you have used these links to leave our site, you should note
              that we do not have any control over that other website.
              Therefore, we cannot be responsible for the protection and privacy
              of any information which you provide whilst visiting such sites,
              and such sites are not governed by this privacy policy. You should
              exercise caution and look at the privacy policy applicable to the
              website in question.
            </p>
            <h5
              style={{
                fontFamily: "margaine sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Controlling Your Personal Information
            </h5>

            <p
              style={{
                color: "GrayText",
                textAlign: "justify",
                fontSize: "15px",
              }}
            >
              You may choose to restrict the collection or use of your personal
              information in the following ways: • Whenever you are asked to
              fill in a form on the website, look for the box that you can click
              to indicate that you do not want the information to be used by
              anybody for direct marketing purposes. • If you have previously
              agreed to us using your personal information for direct marketing
              purposes, you may change your mind at any time by writing to or
              emailing us at If you have any questions, please reach out to us
              at forgewebsinfo@gmail.com. We will not sell, distribute or lease
              your personal information to third parties unless we have your
              permission or are required by law to do so. We may use your
              personal information to send you promotional information about
              third parties which we think you may find interesting if you tell
              us that you wish this to happen. You may request details of
              personal information which we hold about you under the Data
              Protection Act 1998. A small fee will be payable. Please write to
              N17,jaypee aman, Sector 151,Noida , UP, 201310- India if you would
              like a copy of the information held on you. If you believe that
              any information we are holding on you is incorrect or incomplete,
              please write to or email us as soon as possible, at the above
              address. We will promptly correct any information found to be
              incorrect.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Privacy;

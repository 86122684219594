import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';

function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-light">
      <Container>
        <Navbar.Brand as={Link} to="/" style={{ color: 'rgb(0, 176, 255)' }}>Forgewebs</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            {/* <Nav.Link as={Link} to="#">Portfolio</Nav.Link> */}
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            <NavDropdown title="Services" id="collapsible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/website-development">Web Development</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/app-development">App Development</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/graphic-design">Graphic Design</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/digital-marketing">Digital Marketing</NavDropdown.Item>
            </NavDropdown>
           
          </Nav>
          <span className="navbar-text">            
            <Link as={Link} to="/contact"><button className="vvd" >
              <span>Lets Connect</span>
            </button></Link>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;

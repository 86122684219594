import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './Footer.css';

export const Footer = () => {
  return (
    <footer className='bg-light text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div className='me-5 d-none d-lg-block'>
          <a href='https://www.linkedin.com/company/forgewebs/' className='me-4 text-reset'>
          <i className="fa-brands fa-linkedin-in"></i>
          </a>
          <a href='https://www.facebook.com/profile.php?id=61561161313068&mibextid=ZbWKwL ' className='me-4 text-reset'>
          <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a href='https://www.instagram.com/forgewebs?igsh=bGoxc255cWNmcnh4' className='me-4 text-reset'>
          <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
      </section>

      <section className=''>
        <Container className='text-center text-md-start mt-5'>
          <Row className='mt-3'>
            <Col md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{color: '#00b0ff'}}>
                Forgewebs
              </h6>
              <p>
              Elevate your brand today with Forgewebs, a premier web development company. Founded in January 2024, we strive to empower businesses with innovative solutions and exceptional digital experiences.
              </p>
            </Col>

            <Col md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
              <p>
                <Link as={Link} to="/website-development" className='text-reset'>
                  Web Development
                </Link>
              </p>
              <p>
                <Link as={Link} to='/app-development' className='text-reset'>
                  App Development
               </Link>
              </p>
              <p>
                <Link as={Link} to='/digital-marketing' className='text-reset'>
                  Digital Marketing
                </Link>
              </p>
              <p>
                <Link as={Link} to='/graphic-design' className='text-reset'>
                  Graphic Design
                </Link>
              </p>
            </Col>

            <Col md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
              <Link as={Link} to="/faq" className='text-reset'>FAQ's</Link>
              </p>
              <p>
              <Link as={Link} to="/privacy" className='text-reset'>Privacy Policy</Link>
              </p>
              <p>
                <Link as={Link} to="/term-&-condition" className='text-reset'>Terms & Conditions</Link>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Help
                </a>
              </p>
            </Col>

            <Col md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <i className="fa-solid fa-house"></i> Noida Sec-151, Uttar Pradesh, India
              </p>
              <p>
                <i className="fa-solid fa-envelope"></i> info@forgewebs.com              </p>
              <p>
                <i className="fa-solid fa-phone"></i> +91-8595677409
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className='text-reset fw-bold' href='https://forgewebs.com/'>
          Forgewebs.com
        </a>
      </div>
    </footer>
  );
};

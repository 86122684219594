import {Container,Row,Col} from 'react-bootstrap';
import aboutimg1 from '../img/aboutse_11zon.webp';
import TrackVisibility from 'react-on-screen';
import {OurTeam} from '../components/OurTeam';
import './About.css';

export const About=()=>{
    return(<>
        <section className='about-banner'>
            <div className='d-flex text-light py-3 px-3'>
            <h1 style={{fontSize: '70px',}}>Turn On Your business Growth!</h1>
            </div>
        </section>
        <section className="about" id="about">

            <Container>
                <div><TrackVisibility>
                {({ isVisible }) =>(
                    <h2 style={{marginTop: '2rem'}} className={isVisible ? "animate__animated animate__bounce" : "" }>Know More<span style={{color: 'rgb(6, 104, 251)',fontWeight: '700',fontSize: '50px'}}> About Us</span></h2>)}</TrackVisibility></div><hr />
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <p> <b> Welcome to Forgewebs,</b> your one-stop solution for web and app development, digital marketing, and graphic design. Founded in January 2024, we are a dynamic startup dedicated to helping businesses establish and enhance their online presence.
                    At Forgewebs, we understand that in today's digital age, having a strong online presence is crucial for success. Whether you need a stunning website, a user-friendly mobile app, effective digital marketing strategies, or eye-catching graphics, our team of experienced professionals is here to bring your vision to life.
                    </p>
                    </Col>
                    <Col>
                    <img src={aboutimg1} alt="Aboutimg" className="img-fluid"/>
                    </Col>

                </Row>
            </Container>
        </section>
        <section className='py-5' style={{background: '#D9D9D9',}}>
            <div className="container">                
                <div className="row">
                <div><h1 style={{fontSize: '70px'}}>Our Vision</h1></div>
                <p>To become a prime performer, in providing quality Web and Software solutions in the competitive global market place.
We Aim at providing high class web and app development service at low cost and affordable price. We firmly believe in quality more than quantity.
Our website development and application development Service which we offer is not just to make money but to ensure a strong digital footprint of our client. We are dedicated to provide a competitive digital presence of our clients in form of websites and softwares.
</p>
                </div>
            </div>
        </section>
        <section className='py-5'>
            <div className="container">                
                <div className="row">
                <div><h1 style={{fontSize: '70px',color: '#0668FB'}}>Our Mission</h1></div>
                <p>To enhancing the business growth of our customers with creative Design and Development of website and Apps to deliver market-defining high-quality solutions that create value and consistent competitive advantage for our clients around the world.
We understand the service requirements and client’s desire output. We listen to their needs and provide them best solutions.
Likewise, We research the market and develop the best strategies. Hence, enabling us to develop better Websites and Softwares. We consistently bring innovation to our services and strategies. This helps us to provide better services to our clients.
</p>
                </div>
            </div>
        </section>
        <section className='py-5' style={{background: '#D9D9D9',}}>
            <div className="container">                
                <div className="row">
                <div><h1 style={{fontSize: '70px'}}>Our Commitment</h1></div>
                <p>We take pride in our on time delivery and ability to meet quick turn around requests while exceeding customer quality demands.
Customer Satisfaction continues to be of utmost importance to ForgeWebs, as do Consistent quality, Constant innovation, Technology enhancement, Process improvement and Customer orientation. We have developed our core competence and aligning objectives at all levels so as to realize synergy in operations. It is our collaborative approach, creative input, and emphasis on economical solutions that has allowed us to develop an impressive and diverse client list.</p>
                </div>
            </div>
        </section>
        <OurTeam/>
        </>
    )
}
import { useState, useRef } from "react";
import { Container, Row, Col} from "react-bootstrap";
import contactImg from '../img/undraw_contact_us_re_4qqt.svg';
import './Contact.css';
import emailjs from '@emailjs/browser';


 const Contact = () => {
    const form = useRef();
   

    const formInitialDetail = {
        name: '',
        last: '',
        email: '',
        phone: '',
        message: ''        
    };

    const [formDetails, setFormDetails] = useState(formInitialDetail);
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setButtonText('Sending...');
        console.log("Form Details:", formDetails); 
        try {
            await emailjs.sendForm('service_ndpwrig', 'template_yz91x4m', form.current, 'VpsIG6LzgfqV2dC03');
            setStatus({ success: true, message: 'Message sent successfully' });
        } catch (error) {
            console.error('EmailJS send error:', error);
            setStatus({ success: false, message: 'Something Went Wrong' });
        }
        
        setButtonText('Send');
        setFormDetails(formInitialDetail);
    };

    return (<>
         
    
        <section className="contact" id="contact">
            <Container>
                <Row className="align-item-center">
                    <Col md={6}>
                        <img src={contactImg} alt="contactImg" />
                    </Col>
                    <Col md={6}>
                        <h2 className="animate_animated animate_rubberBand">Get In Touch...</h2>
                        <form ref={form} onSubmit={handleOnSubmit} action="/submit" method="post">
                            <Row>
                                <Col sm={6} className="px-1">
                                    <input type="text" value={formDetails.name} name="name" placeholder="First Name:" onChange={(e) => onFormUpdate('name', e.target.value)} required />
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input type="text" value={formDetails.last} name="last" placeholder="Last Name:" onChange={(e) => onFormUpdate('last', e.target.value)} required />
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input type="email" value={formDetails.email} name="email" placeholder="Email Address:" onChange={(e) => onFormUpdate('email', e.target.value)} required/>
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input type="tel" value={formDetails.phone} name="phone" placeholder="Whatsapp No.:" onChange={(e) => onFormUpdate('phone', e.target.value)} required/>
                                </Col>
                                
                                <Col>
                                    <textarea rows='1' value={formDetails.message} name="message" placeholder="Message:" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                    <button type="submit" style={{ borderRadius: '30px', border: '2px solid' }}><span>{buttonText}</span></button>
                                </Col>
                                {status.message &&
                                    <Col>
                                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                    </Col>}
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
};
export default Contact;


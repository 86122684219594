import React, { useState } from "react";


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleOpen} style={styles.question}>
        {question}
      </button>
      {isOpen && <div style={styles.answer}>{answer}</div>}
    </div>
  );
};

 const FAQ = () => {
 
    "A hybrid program means that learners will have access to both self-paced classes and live online interactive sessions. If you prefer to complete the course faster, you can focus on the self-paced modules and still have the opportunity to attend live sessions for additional support and interaction.";
  const faqData = [
    {
      question: "1. Will my website be mobile-friendly?",
      answer:
        "Yes, we ensure to develop a fully responsive website that would make your visitors appealing and deliver an optimized browsing experience.",
    },
    {
      question: "2.  How long does it take to build a website?",
      answer:
        "Depending upon the complexity it would take around 1 to 4 months starting from scratch till the end .",
    },
    {
      question: "3. How many pages do I get with my website?",
      answer:
        "We can work on it depending on your requirements. We would recommend developing a website with a few pages as it would help in optimizing your website with SEO in future.",
    },
    {
      question: "4. Do you provide SEO?",
      answer:
        "Yes, we provide Web Development integrated with SEO.",
    },
    {
      question: "5. Will you maintain my site for me?",
      answer:
        "Of course, we can help you out with proper and professional website maintenance in addition to development as it supports in achieving stabilized growth, keeping your business inclined.",
    },
    {
      question: "6. Can you handle the ongoing website development project?",
      answer:
        "Yes, providing a user friendly website to you is our priority. We happily takeover the ongoing project.",
    },
    {
      question: "7.  What platform do you build websites on?",
      answer:
        "We generally use React and javascript to develop websites.",
    },
    {
      question: "8. Why should I use ForgeWebs over another agency?",
      answer:
        "Our clients use us because of our broad capabilities. They know we can successfully deliver complex app and online portal development projects.We have a motto of building trust and client relationship rather than money.This helps us to deliver a high quality website and app development projects on time.",
    },
    {
      question: "9. Can you build apps for both iOS and Android?",
      answer:
        "Yes, most of the apps we make support both iOS and Android mobile platforms. It’s common for people to want their applications to work across web and mobile devices, so we use development frameworks such as React and React native that enable us to support both platforms quickly.",
    },
    {
      question: "10. How do you make sure you build what I want, and don’t get it wrong?",
      answer:
        "We don’t just blindly begin building your requirements; we start by having a meeting to understand the commercial factors that are driving the need for your project in project management process. We aim to learn enough about you and your business to enable us to advise you and ensure our vision is aligned.You don’t just want us to tell you that we understand your project; we need to prove it to you. This is why once we understand your business and your requirements, we can scope out your project, create wireframes, and quote. Plans are in place and agreed upon by both sides before we design a pixel or write a line of code.",
    },
    {
      question: "11. What programming languages and frameworks do you use?",
      answer:
        "We like to use open source languages wherever possible with HTML, CSS ,JavaScript,React.js and Bootstrap for front-end,Python-Flask, on the back end.",
    },
    {
      question: "12. Do you offer website development service?",
      answer: "Yes, exactly that’s what we do.. We at ForgeWebs Design Website, Develop Website and Deploy Website."
    },
    {
      question: "13. What services do you offer?",
      answer: "Like mentioned in our website we provide following services:-Website Development,Mobile Application development,	Web application Development,Graphics Design,Website and Application maintainance,UI/UX Design and other"
    },
    {
      question: "14. I have an idea regarding website development but don’t know how to execute it in codes can you help me ?",
      answer: "Your idea and our expertise in web development will sureply make you see your idea Gloom."
    },
    // {
    //   question: "",
    //   answer: ""
    // }

  ];

  return (
    <section className="faq" id="faq" style={{marginTop: '5rem'}}>
      <div className="container">
      <div style={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{fontFamily: 'margaine sans-serif',fontWeight: '900',fontStyle: 'normal'}}><span style={{color: 'rgb(6, 104, 251)',fontWeight: '700'}}> FAQ's ?</span></h1>{" "}
          
        </div>
        <hr />
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div></div>
    </section>
  );
};
const styles = {
  container: {
    width: "100%",
    margin: "auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  question: {
    backgroundColor: "#f1f1f1",
    cursor: "pointer",
    padding: "15px",
    width: "100%",
    border: "none",
    textAlign: "left",
    outline: "none",
    fontSize: "15px",
    marginBottom: "10px",
  },
  answer: {
    padding: "18px",
      backgroundColor: '#f9f9f9',
    border: "1px solid #ddd",
    marginBottom: "10px",
  },
};
export default FAQ;